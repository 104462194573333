import { atom, useAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { reclaim } from "../../reclaim-api";
import { ConnectedAccount } from "../../reclaim-api/Accounts";
import { CalendarView } from "../../reclaim-api/Calendars";
import { normalize } from "../../utils/objects";
import { useCaptureError } from "../useCaptureError";
import { useNotifications } from "../useNotifications";
import { useOurRouter } from "../useOurRouter";

interface AccountsAtom {
  accounts: ConnectedAccount[] | undefined;
  connectedCalendars: CalendarView<true>[] | undefined;
  accountsMap: Record<number, ConnectedAccount> | undefined;
  connectedCalendarsMap: Record<number, CalendarView<true>> | undefined;
}

const accountsAtom = atom<AccountsAtom>({
  accounts: undefined,
  connectedCalendars: undefined,
  accountsMap: undefined,
  connectedCalendarsMap: undefined,
});

const setAccountsAtom = atom(undefined, (get, set, accountsData: AccountsAtom) => set(accountsAtom, accountsData));

const initAtom = atom<boolean>(false);
const setInitAtom = atom(undefined, (get, set, initialized: boolean) => set(initAtom, initialized));

const loadingAtom = atom<boolean>(false);
const setLoadingAtom = atom(undefined, (get, set, loading: boolean) => set(loadingAtom, loading));

const loadingErrorAtom = atom<Error | undefined>(undefined);
const setLoadingErrorAtom = atom(undefined, (get, set, loadingError: Error | undefined) =>
  set(loadingErrorAtom, loadingError)
);

export type UseAccountsActionsReturnType = {
  load: () => Promise<ConnectedAccount[] | undefined>;
  setNewMainAccount: (account: ConnectedAccount) => Promise<void>; // triggers redirect
  deleteAccount: (accountId: number) => Promise<void>;
};

export const useAccountsActions = (): UseAccountsActionsReturnType => {
  const [, setAccounts] = useAtom(setAccountsAtom);
  const [, setInit] = useAtom(setInitAtom);
  const [, setLoading] = useAtom(setLoadingAtom);
  const [, setLoadingError] = useAtom(setLoadingErrorAtom);

  const router = useOurRouter();
  const { captureError } = useCaptureError();
  const { sendNotification } = useNotifications();

  const load = useCallback(async () => {
    let accounts: ConnectedAccount[] | undefined = undefined;

    void setLoading(true);
    try {
      accounts = await reclaim.accounts.list();
      void setLoadingError(undefined);
    } catch (cause) {
      void setLoadingError(new Error("Failed to load account info", { cause }));
      captureError(new Error("Failed to load account info", { cause }), {
        severity: "error",
        statusCode: 5000,
      });
      void router.push("/500");
    } finally {
      void setLoading(false);
    }

    const connectedCalendars = accounts?.flatMap(({ connectedCalendars }) => connectedCalendars).filter((i) => !!i) as
      | CalendarView<true>[]
      | undefined;

    const accountsMap = accounts && normalize(accounts, "id");
    const connectedCalendarsMap = connectedCalendars && normalize(connectedCalendars, "id");

    if (!!accounts) {
      await setAccounts({ accounts, connectedCalendars, accountsMap, connectedCalendarsMap });
      await setInit(true);
      return accounts;
    }
  }, [setAccounts, setInit, setLoading, setLoadingError, captureError, router]);

  const setNewMainAccount = useCallback(
    async (account: ConnectedAccount) => {
      if (!account.switchToMainURI) {
        sendNotification({
          message: "Cannot set account to main. Please contact support if this issue persists.",
          type: "error",
        });
        return;
      }

      await reclaim.accounts.authRedirect(account.switchToMainURI, {
        redirect: router.asPath,
        accountId: account.id,
      });
    },
    [router.asPath, sendNotification]
  );

  const deleteAccount = useCallback(
    async (accountId: number) => {
      try {
        await reclaim.accounts.delete(accountId);
        await load();
        sendNotification({ message: "Account deleted." });
      } catch (cause) {
        sendNotification({
          message: "Could not delete account. Please contact support if this problem persists.",
          type: "error",
        });
      }
    },
    [load, sendNotification]
  );

  const actions = useMemo<UseAccountsActionsReturnType>(
    () => ({ load, setNewMainAccount, deleteAccount }),
    [load, setNewMainAccount, deleteAccount]
  );

  return actions;
};

export type UseAccountsStateReturnType = {
  accounts: readonly Readonly<ConnectedAccount>[] | undefined;
  connectedCalendars: readonly Readonly<CalendarView<true>>[] | undefined;
  accountsMap: Readonly<Record<number, Readonly<ConnectedAccount>>> | undefined;
  connectedCalendarsMap: Record<number, Readonly<CalendarView<true>>> | undefined;
  initialized: boolean;
  loading: boolean;
  loadingError: Error | undefined;
  mainAccount: Readonly<ConnectedAccount> | undefined;
  getCalendarColor: (calendarId: number | undefined) => string | undefined;
};

export const useAccountsState = (): UseAccountsStateReturnType => {
  const [{ accounts, connectedCalendars, accountsMap, connectedCalendarsMap }] = useAtom(accountsAtom);
  const [initialized] = useAtom(initAtom);
  const [loading] = useAtom(loadingAtom);
  const [loadingError] = useAtom(loadingErrorAtom);

  const mainAccount = useMemo(() => accounts?.find(({ main }) => main), [accounts]);

  const getCalendarColor = useCallback(
    (calendarId: number | undefined) => {
      if (typeof calendarId !== "number" || !connectedCalendarsMap || !connectedCalendarsMap[calendarId]) return;
      return connectedCalendarsMap[calendarId].colorHex;
    },
    [connectedCalendarsMap]
  );

  const state = useMemo<UseAccountsStateReturnType>(
    () => ({
      accounts,
      initialized,
      loading,
      loadingError,
      connectedCalendars,
      mainAccount,
      accountsMap,
      connectedCalendarsMap,
      getCalendarColor,
    }),
    [
      accounts,
      initialized,
      loading,
      loadingError,
      connectedCalendars,
      mainAccount,
      accountsMap,
      connectedCalendarsMap,
      getCalendarColor,
    ]
  );

  return state;
};
